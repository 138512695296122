import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { images } from '/images'
import { getSteamFromConfig, getSteamToConfig, mmConfig } from '/util/gsapHelpers'

import styles from './Payoff.css'

gsap.registerPlugin(ScrollTrigger, SplitText)

export function Payoff() {
  const componentRef = React.useRef(null)
  const layoutRef = React.useRef(null)
  const componentHeightInVh = 450 // determines scrolling length

  const payoffs = [
    {
      heading: 'Voeg innovaties toe die mensen dichter bij elkaar brengen.',
      image: images.payoffInnovaties,
      firstWord: 1,
    },
    {
      heading: 'Zoals onze Smaakchefs, die extra gezelligheid meenemen naar het eten op woongroepen.',
      image: images.payoffSmaakchefs,
      firstWord: 2,
    },
    {
      heading: 'Of ons Slim Menu, waardoor iedereen zin heeft om aan te schuiven.',
      image: images.payoffSlimMenu,
      firstWord: 'random',
    },
  ]

  useGSAP(() => {

    const mm = gsap.matchMedia()

    mm.add(mmConfig, (ctx) => {
      const { isMobile } = ctx.conditions

      /*
        pinning concept:
        The layout component has a height of 100vh and is pinned with the main component as its trigger.
        The layout component contains payoff-sections that are all stacked in the same one grid area.
        The height of the main component determines how long the scrolling takes.
        Each payoff-section gets its own fade-in timeline, also with the main component as trigger
        */

      // layout pin
      ScrollTrigger.create({
        trigger: componentRef.current,
        start: 'top top',
        end: 'bottom bottom',
        pin: layoutRef.current,
      })

      function getTriggerPosition(i) {
        const totalScrollH = componentHeightInVh - 100
        const triggerPercentage = i * totalScrollH / payoffs.length
        return `top -${triggerPercentage}%`
      }

      payoffs.forEach((payoff, i) => {
        const triggerPosition = getTriggerPosition(i)
        const sectionFadeDuration = 0.5

        if (i > 0) {
          // first payoff scrolls into view, so it should not fade in
          const tlSectionFade = gsap.timeline()
          tlSectionFade.fromTo(
            `[data-payoff="${i}"]`,
            { opacity: 0 },
            { opacity: 1, duration: sectionFadeDuration, ease: 'power1.inOut' }
          )

          ScrollTrigger.create({
            trigger: componentRef.current,
            start: triggerPosition,
            end: triggerPosition,
            // with timed animation (as opposed to scrub), using same start and
            // end position with 'play none reverse none' will trigger animation
            // back and forth at same point
            toggleActions: 'play none reverse none',
            animation: tlSectionFade,
          })
        }

        const headingSelector = `[data-payoff-heading="${i}"]`
        const splitHeading = new SplitText(headingSelector, { types: 'words' } )

        const tlHeadingSteam = gsap.timeline()
          .fromTo(
            splitHeading.words,
            getSteamFromConfig(isMobile),
            getSteamToConfig({ each: 0.05 })
          )

        ScrollTrigger.create({
          trigger: componentRef.current,
          start: triggerPosition,
          animation: tlHeadingSteam,
        })

        const tlHeadingScrub = gsap.timeline()
          .fromTo(
            headingSelector,
            { y: 75 },
            { y: 0 }
          )
        ScrollTrigger.create({
          trigger: componentRef.current,
          start: triggerPosition,
          end: getTriggerPosition(i + 1),
          scrub: 0.25, // use scrub to give user feeling of progress while section is pinned
          animation: tlHeadingScrub,
        })
      })
    })
  },

  { scope: componentRef }
  )

  return (
    <div className={styles.component} ref={componentRef} style={{ '--height-in-vh': componentHeightInVh }} data-component>
      <div className={styles.layout} ref={layoutRef}>
        {payoffs.map((payoff, i) => (
          <div key={i} className={cx(styles.payoff, styles[`payoff${i}`])} data-payoff={i}>
            <h2 className={styles.heading} data-payoff-heading={i}>
              {payoff.heading}
            </h2>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={payoff.image} alt="" data-payoff-image={i} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
